import React from "react"
import styled from "@emotion/styled"
import { FormName, sendFormRequest } from "../../utils/network"
import { Input, Textarea } from "../Input/Input"
import { PrimaryButton } from "../PrimaryButton/PrimaryButton"
import { breakpoint } from "../../theme/theme"
import { Status, StatusMessage } from "../StatusMessage/StatusMessage"

interface ContactFormProps {
  callToAction: string
}

export interface ContactFormState {
  firstName: string
  lastName: string
  email: string
  company: string
  description: string
}

export const ContactForm: React.FC<ContactFormProps> = ({ callToAction }) => {
  const [firstName, setFirstName] = React.useState("")
  const [lastName, setLastName] = React.useState("")
  const [email, setEmailAddress] = React.useState("")
  const [company, setCompanyName] = React.useState("")
  const [description, setDescription] = React.useState("")
  const [responseMessage, setResponseMessage] = React.useState<
    Status | undefined
  >(undefined)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    const response = await sendFormRequest<ContactFormState>(FormName.Contact, {
      firstName,
      lastName,
      email,
      company,
      description,
    })

    if (response.ok) {
      setResponseMessage({
        type: "success",
        message: "Form Submission Successful!",
      })
    } else {
      setResponseMessage({
        type: "error",
        message: "Form Submission Unsuccessful!",
      })
    }
    setFirstName("")
    setLastName("")
    setEmailAddress("")
    setCompanyName("")
    setDescription("")
  }

  const handleEmailAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailAddress(e.target.value)
  }

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value)
  }

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value)
  }
  const handleCompanyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName(e.target.value)
  }

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDescription(e.target.value)
  }

  return (
    <Form onSubmit={handleSubmit} name={FormName.Contact}>
      <InputRow>
        <Input
          label="First Name"
          onChange={handleFirstNameChange}
          value={firstName}
          placeholder="Enter your first name"
          name="firstName"
        />
        <Input
          label="Last Name"
          onChange={handleLastNameChange}
          value={lastName}
          placeholder="Enter your last name"
          name="lastName"
        />
      </InputRow>

      <InputRow>
        <Input
          label="Email Address"
          onChange={handleEmailAddressChange}
          value={email}
          placeholder="Enter your email address"
          name="email"
        />
        <Input
          label="Company Name"
          onChange={handleCompanyNameChange}
          value={company}
          placeholder="Enter your company name"
          name="company"
        />
      </InputRow>

      <Textarea
        label="Project Description"
        onChange={handleDescriptionChange}
        value={description}
        placeholder="Please provide a description of your project"
        name="description"
      />
      {responseMessage && <StatusMessage {...responseMessage} />}
      <PrimaryButton type="submit">{callToAction}</PrimaryButton>
    </Form>
  )
}

const InputRow = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: "2em",
  [breakpoint("md")]: {
    gridTemplateColumns: "1fr 1fr",
  },
})

const Form = styled("form")({
  display: "grid",
  gap: "2em",
})
