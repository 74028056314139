import styled from "@emotion/styled"
import { createStandardGradient } from "../../theme/theme"

export const AccentDivider = styled.div(({ theme }) => ({
  height: "4px",
  width: "64px",
  background: createStandardGradient(
    theme.palette.primary.main,
    theme.palette.primary.light
  ),
}))
