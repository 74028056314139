import React from "react"
import styled from "@emotion/styled"
import { InputLabel } from "../InputLabel/InputLabel"
import { InputContainer } from "../InputContainer/InputContainer"

interface InputProps extends Omit<React.HTMLProps<HTMLInputElement>, "as"> {
  label: string
}
export const Input: React.FC<InputProps> = ({
  label,
  type = "text",
  ...other
}) => {
  const inputId = other.id ? other.id : `input-${label}`
  return (
    <InputContainer>
      <InputLabel htmlFor={inputId}>{label}</InputLabel>
      <StyledInput type={type} {...other} id={inputId} />
    </InputContainer>
  )
}

interface TextareaProps
  extends Omit<React.HTMLProps<HTMLTextAreaElement>, "as"> {
  label: string
}

export const Textarea: React.FC<TextareaProps> = ({ label, ...other }) => {
  const inputId = other.id ? other.id : `input-${label}`
  return (
    <InputContainer>
      <InputLabel htmlFor={inputId}>{label}</InputLabel>
      <StyledTextarea type="text" rows={5} {...other} id={inputId} />
    </InputContainer>
  )
}

const StyledInput = styled.input(({ theme }) => ({
  border: `1px solid lightgrey`,
  borderRadius: "3px",
  padding: "15px 20px",
  fontFamily: theme.typography.fontFamily,
}))

export const StyledTextarea = StyledInput.withComponent("textarea")
