export enum FormName {
  Newsletter = "newsletter",
  Contact = "contact",
}

const API_ENDPOINT =
  process.env.GATSBY_API_ENDPOINT || "https://api.valenciandigital.workers.dev"

export const sendFormRequest = async <T>(formName: FormName, data: T) => {
  return fetch(`${API_ENDPOINT}/${formName}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
}
