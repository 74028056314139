import { graphql } from "gatsby"
import { ProjectInquiry } from "../ProjectInquiry/ProjectInquiry"

export const query = graphql`
  query ProjectInquiryQuery {
    contactPage: markdownRemark(
      fileAbsolutePath: { regex: "/netlify/contact.md/" }
    ) {
      frontmatter {
        title
        subtitle
        caption
        buttonText
      }
    }
    site {
      ...SiteFragment
    }
    header: markdownRemark(fileAbsolutePath: { regex: "/netlify/header.md/" }) {
      ...HeaderFragment
    }
    footer: markdownRemark(fileAbsolutePath: { regex: "/netlify/footer.md/" }) {
      ...FooterFragment
    }
  }
`
export default ProjectInquiry
