import React from "react"
import { PageProps } from "gatsby"
import { Paper } from "../components/Paper/Paper"
import { Typography } from "../components/Typography/Typography"
import { ContactForm } from "../components/ContactForm/ContactForm"
import { AccentDivider } from "../components/AccentDivider/AccentDivider"
import styled from "@emotion/styled"
import { breakpoint } from "../theme/theme"
import { BasePageData, withLayout } from "../components/Layout/layout.hocs"

interface ProjectInquiryData extends BasePageData {
  contactPage: {
    frontmatter: {
      title: string
      subtitle: string
      caption: string
      buttonText: string
    }
  }
}

type ProjectInquiryProps = PageProps<ProjectInquiryData>

export const ProjectInquiry: React.FC<ProjectInquiryProps> = withLayout(
  { title: "Project Inquiry", variant: "light" },
  (props) => {
    const { title, subtitle, caption, buttonText } =
      props.data.contactPage.frontmatter

    return (
      <>
        <StyledPaper color="blue" gradient>
          <Typography variant="h1">{title}</Typography>
          <AccentDivider />
          <Typography variant="h4">{subtitle}</Typography>
          <Typography variant="p">{caption}</Typography>
        </StyledPaper>

        <FormContainer>
          <ContactForm callToAction={buttonText}></ContactForm>
        </FormContainer>
      </>
    )
  }
)

const StyledPaper = styled(Paper)({
  display: "flex",
  flexDirection: "column",
  gap: "1em",
  padding: "8em 2em 4em",
  [breakpoint("lg")]: {
    padding: "8em 8em 4em",
  },
})

const FormContainer = styled.div({
  padding: "5em 1em",
  [breakpoint("lg")]: {
    width: "50%",
    margin: "0 auto",
  },
})
