import React from "react"
import styled from "@emotion/styled"
import { Typography } from "../Typography/Typography"

export interface Status {
  type: "error" | "success"
  message: string
}

export const StatusMessage: React.FC<Status> = ({ type, message }) => {
  return (
    <Container type={type}>
      <Typography variant="p">{message}</Typography>
    </Container>
  )
}

const Container = styled("div")<{ type: "error" | "success" }>(
  ({ theme, type }) => ({
    padding: "1rem 2rem",
    backgroundColor:
      type === "success"
        ? theme.palette.success.background
        : theme.palette.error.background,
    color:
      type === "success"
        ? theme.palette.success.text
        : theme.palette.error.text,
    borderRadius: "10px",
  })
)
